<template>
  <div>
    <overlay-loader :loading="false" />
    <hcc-table :title="$t('users.title')" :columns="columns" :rows="users"
      :pagination="users.length > 10" :rowsPerPage="10" enableSearch
      @search="() => { }">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'ticket-actions'"
          class="ticket__actions">
          <hcc-button-icon @click="editUser(props.row)"
            v-tooltip="$t('users.edit')">
            <edit-icon />
          </hcc-button-icon>
        </div>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </hcc-table>
    <edit-user-modal @update-user="updateUser" />
  </div>
</template>

<script>
import HccTable from '../components/HccTable.vue'
import HccButtonIcon from '../components/HccButtonIcon.vue'
import OverlayLoader from '../components/OverlayLoader.vue'
import EditIcon from '@/assets/icons/pencil.svg'
import EditUserModal from '../components/EditUserModal.vue'
import { mapState } from 'vuex'

export default {
  components: { HccTable, OverlayLoader, HccButtonIcon, EditIcon, EditUserModal },
  mounted() {
    this.$store.dispatch('getUsers')
  },
  computed: {
    ...mapState({
      users: state => state.users,
      loading: state => state.loading
    }),
    columns() {
      return [
        {
          label: this.$t('tickets.name'),
          field: 'userName',
          sortable: false
        },
        {
          label: this.$t('tickets.phone'),
          field: 'phone'
        },
        {
          label: this.$t('tickets.email'),
          field: 'email'
        },
        {
          label: this.$t('users.fbId'),
          field: 'userId'
        },
        {
          label: this.$t('users.points'),
          field: 'puntos'
        },
        {
          label: this.$t('users.code'),
          field: 'ticketId'
        },
        {
          label: this.$t('tickets.actions'),
          field: 'ticket-actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    editUser(user) {
      this.$modal.show('edit-user-modal', user)
    },
    updateUser() {
      this.$store.dispatch('getUsers')
    }
  }
}
</script>

<style lang="scss">
@import "~styles/views/users.scss";
</style>
