<template>
  <hcc-modal name="edit-user-modal" :title="$t('users.edit')"
    :scrollable="false" @before-open="beforeOpen" @opened="setData"
    @confirm="handleConfirm" @closed="updateUser"
    :disableConfirmButton="!hasEdits">
    <div class="users__info">
      <hcc-input :label="$t('tickets.name')" v-model="name" size="xlg" />
    </div>
    <div class="users__info">
      <hcc-input :label="$t('users.age')" v-model="age" size="xlg" />
    </div>
    <div class="users__info">
      <hcc-input :label="$t('tickets.phone')" v-model="phone" size="xlg" />
    </div>
    <div class="users__info">
      <hcc-input :label="$t('tickets.email')" v-model="email" size="xlg" />
    </div>
    <div class="users__info">
      <hcc-input :label="$t('users.postalCode')" v-model="postalCode"
        size="xlg" />
    </div>
  </hcc-modal>
</template>

<script>
import HccModal from '../components/HccModal/index.vue'
import HccInput from '../components/HccInput.vue'

export default {
  components: { HccModal, HccInput },
  data() {
    return {
      user: null,
      name: '',
      age: null,
      phone: null,
      email: '',
      postalCode: 0
    }
  },
  computed: {
    hasEdits() {
      return this.name !== this.user?.userName || this.age !== this.user?.age.amount || this.phone !== this.user?.phone || this.email !== this.user?.email || this.postalCode !== this.user?.zipCode
    }
  },
  methods: {
    beforeOpen(event) {
      this.user = event.params
    },
    setData() {
      this.name = this.user.userName
      this.age = this.user.age.amount
      this.phone = this.user.phone
      this.email = this.user.email
      this.postalCode = this.user.zipCode
    },
    handleConfirm() {
      if (this.hasEdits) {
        const editedUser = {
          ...this.user,
          userName: this.name,
          age: {
            amount: parseInt(this.age),
            unit: 'year'
          },
          phone: this.phone,
          email: this.email,
          zipCode: this.postalCode

        }
        this.$store.dispatch('updateUser', editedUser)
      }
    },
    updateUser() {
      this.$emit('update-user')
    }
  }
}
</script>
